import banner1 from "../../asserts/banner-slide1.webp";
import banner2 from "../../asserts/banner-wordpress.webp";
import banner3 from "../../asserts/banner-react-home.webp";
import banner4 from "../../asserts/banner-laravel.webp";

export const HomeSlider = () => {
    return(
        <div id="carouselExampleCaptions" className="carousel slide">
            <div className="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4"></button>
            </div>
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img src={banner1} srcSet={`${banner1} 600w, ${banner1} 1200w`} sizes="(max-width: 600px) 100vw, 600px" height="100%" width="100%" className="d-block img-fluid w-100 h-100" alt="banner1" loading="lazy"/>
                    <div className="carousel-caption">
                        <h2 className="h1" >Web Developer</h2>
                        <p className="w-75 m-auto d-none d-md-block text-center">At EgeneusCo, our team of skilled web developers design and build custom web applications that drive business results. With expertise in the latest technologies and frameworks, we create scalable, secure, and user-friendly solutions that meet our clients' unique needs. From e-commerce platforms to complex enterprise systems, we bring your vision to life with precision, creativity, and a passion for innovation.</p>
                    </div>
                </div>
                <div className="carousel-item">
                    <img src={banner3} srcSet={`${banner3} 600w, ${banner3} 1200w`} sizes="(max-width: 600px) 100vw, 600px" height="100%" width="100%" className="d-block img-fluid w-100 h-100" alt="banner3" loading="lazy"/>
                    <div className="carousel-caption">
                        <h2>React Developer</h2>
                        <p className="w-75 m-auto d-none d-md-block text-center">As a React.js Developer, We create user-friendly and high-performance web applications using React.js and its rich ecosystem of libraries and tools. Our expertise lies in designing and implementing modern UI/UX designs, while maintaining optimal performance and security. With a solid background in web development and React.js, We ensure smooth functionality and performance across devices. Contact us today to discuss your next React.js project!</p>
                    </div>
                </div>
                <div className="carousel-item">
                    <img src={banner4} srcSet={`${banner4} 600w, ${banner4} 1200w`} sizes="(max-width: 600px) 100vw, 600px" height="100%" width="100%" className="d-block img-fluid w-100 h-100" alt="banner3" loading="lazy"/>
                    <div className="carousel-caption">
                        <h2>Laravel Developer</h2>
                        <p className="w-75 m-auto d-none d-md-block text-center">As a Laravel Developer, We specialize in building robust, scalable, and secure web applications using the Laravel PHP framework. Our expertise includes architecting, coding, and maintaining enterprise-level web applications, as well as optimizing the performance of web applications for high-traffic websites. Some key technologies I've worked with include MySQL, PostgreSQL, Redis, Elasticsearch, AWS, Docker, Kubernetes, and CI/CD tools. Contact us today to discuss your next Laravel project!</p>
                    </div>
                </div>
                <div className="carousel-item">
                    <img src={banner2} srcSet={`${banner2} 600w, ${banner2} 1200w`} sizes="(max-width: 600px) 100vw, 600px" height="100%" width="100%" className="d-block img-fluid w-100 h-100" alt="banner2" loading="lazy"/>
                    <div className="carousel-caption">
                        <h2>Wordpress Developer</h2>
                        <p className="w-75 m-auto d-none d-md-block text-center">As a WordPress Developer, We create high-performance and responsive websites using WordPress and its robust ecosystem of plugins and themes. Our expertise lies in customizing themes and plugins to suit clients' specific needs. With a solid background in web development and WordPress, We ensure smooth functionality and performance across devices. Contact us today to discuss your next WordPress project!</p>
                    </div>
                </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
            </button>
        </div>
    )
}